@import './typography.variables';

@mixin font-style-h1($font-weight: $font-weight-bold) {
  line-height: $line-height-h1;
  font-size: $font-size-h1;
  font-weight: $font-weight;
}

@mixin font-style-h2($font-weight: $font-weight-bold) {
  line-height: $line-height-h2;
  font-size: $font-size-h2;
  font-weight: $font-weight;
}

@mixin font-style-h3($font-weight: $font-weight-bold) {
  line-height: $line-height-h3;
  font-size: $font-size-h3;
  font-weight: $font-weight;
}

@mixin font-style-h4($font-weight: $font-weight-bold) {
  line-height: $line-height-h4;
  font-size: $font-size-h4;
  font-weight: $font-weight;
}

@mixin font-style-subtitle($font-weight: $font-weight-medium) {
  line-height: $line-height-subtitle;
  font-size: $font-size-subtitle;
  font-weight: $font-weight;
}

@mixin font-style-default($font-weight: $font-weight-regular) {
  line-height: $line-height-default;
  font-size: $font-size-default;
  font-weight: $font-weight;
}

@mixin font-style-small($font-weight: $font-weight-regular) {
  line-height: $line-height-small;
  font-size: $font-size-small;
  font-weight: $font-weight;
}

@mixin font-style-tiny($font-weight: $font-weight-regular) {
  line-height: $line-height-tiny;
  font-size: $font-size-tiny;
  font-weight: $font-weight;
}

@mixin font-style-super-small($font-weight: $font-weight-regular) {
  line-height: $line-height-super-small;
  font-size: $font-size-super-small;
  font-weight: $font-weight;
}

@mixin font-style-extra-small($font-weight: $font-weight-regular) {
  line-height: $line-height-extra-small;
  font-size: $font-size-extra-small;
  font-weight: $font-weight;
}

@mixin font-style-extra-tiny($font-weight: $font-weight-regular) {
  line-height: $line-height-extra-tiny;
  font-size: $font-size-extra-tiny;
  font-weight: $font-weight;
}

// --

@mixin one-line-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

@mixin list-styles-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin button-styles-reset {
  border: none;
  background-color: transparent;
}
