/* font family variables */

/* todo: consider adding $font-family-condensed */
$font-family-default: latoweb, arial, sans-serif, currency;


/* line height variables */

$line-height-h1: 56px;
$line-height-h2: 44px;
$line-height-h3: 36px;
$line-height-h4: 32px;
$line-height-subtitle: 24px;
$line-height-default: 24px;
$line-height-small: 20px;
$line-height-tiny: 18px;
$line-height-extra-small: 16px;
$line-height-super-small: 14px;
$line-height-extra-tiny: 12px;


/* font size variables */

$font-size-h1: 44px;
$font-size-h2: 36px;
$font-size-h3: 28px;
$font-size-h4: 24px;
$font-size-subtitle: 18px;
$font-size-default: 16px;
$font-size-small: 14px;
$font-size-tiny: 12px;
$font-size-super-small: 11px;
$font-size-extra-small: 10px;
$font-size-extra-tiny: 8px;


/* font weight variables */

$font-weight-regular: normal;
$font-weight-medium: 500;
$font-weight-bold: bold;
