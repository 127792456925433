@import 'src/design-system/styles/typography.mixins';

.noData {
  @include font-style-small();
  color: var(--text-30);
  text-align: center;
  padding: 33px 0;
  margin: 0;
}

.skeletonWrapper {
  list-style: none;
  padding: 12px 0;
  margin: 0;

  & div:nth-child(2) {
    border-color: var(--shape-50);
    box-shadow: none;
  }
}
