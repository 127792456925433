@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/layout-variables';
@import 'src/design-system/styles/themes/palettes/sportColors';

$content-indent: 16px;

.container {
  background-color: var(--shape-10);

  grid-area: interestingMatches;
  padding-top: $content-indent;
  overflow: hidden;
  border-radius: 8px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $content-indent;
  padding-bottom: 4px;

  & h5 {
    @include font-style-subtitle($font-weight-bold);

    margin: 0;
  }

  & label {
    display: flex;
    align-items: center;
    margin: 0;
    cursor: pointer;

    & span {
      @include font-style-small;
      padding: 0 8px;
    }
  }
}

@each $sport, $color in $sportColors {
  .#{$sport} {
    border-bottom: 2px solid $color;
  }
}

.filters {
  padding-right: 32px;
}

.header {
  background-color: transparent;
  padding: 8px $content-indent;

  & p {
    @include font-style-small($font-weight-bold);
    display: flex;
    align-items: center;

    & svg {
      width: 14px;
      height: 14px;
    }
  }
}

.matchRow {
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  padding: 8px $content-indent;
  border-bottom: 1px solid var(--shape-50);

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: var(--grid-bet-bg);
  }

  &:hover .stakesContainer button:not(:global(.disabled)):global(.conflict):not(:hover):not(:global(.active)) {
    background-color: var(--grid-bet-bg);
  }

  &:hover .stakesContainer button:not(:global(.disabled)):not(:hover):not(:global(.active)) {
    background-color: var(--shape-10);
  }

  &:hover ul {
    background: linear-gradient(270deg, var(--grid-bet-bg) 90%, transparent 100%);
  }

  &:hover ul > li > div {
    &::after {
      background: var(--grid-bet-bg);
    }
  }
}

.matchDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stakesContainer {
  display: flex;
  align-items: center;
  padding: 0 4px 0 8px;

  &.threeMainLineStake {
    justify-content: flex-end;
  }
}

.betsCount {
  @include font-style-tiny($font-weight-medium);
  font-size: 11px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
}

.contentWithinBets {
  @include font-style-super-small;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  min-height: 63px;
  width: 506px;

  @media screen and (max-width: $grid-column-screen-width) {
    width: 380px;
  }
}

.mainLineStakes {
  margin: 0 4px;
}

.threeMainLineStake {
  width: 384px;
}
